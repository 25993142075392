// src/HandlingCostForm.js
import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Input, InputNumber, message, Select } from 'antd';
import axios from 'axios';
import config from './Config';

const { Option } = Select;

const HandlingCostForm = ({ visible, onClose, handlingCost, onSave }) => {
  const [form] = Form.useForm();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (visible && currencies.length === 0) {
      fetchCurrencies();
    }

    if (handlingCost) {
      form.setFieldsValue({
        ...handlingCost,
        currencyId: handlingCost.currencyId || currencies[currencies.length - 1]?.currencyId,
      });
    } else {
      form.resetFields();
      if (currencies.length > 0) {
        form.setFieldsValue({ currencyId: currencies[currencies.length - 1]?.currencyId });
      }
    }
  }, [handlingCost, form, currencies, visible]);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/currencies/active`);
      const activeCurrencies = response.data.filter((currency) => currency.isEnabled);
      setCurrencies(activeCurrencies);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
      message.error('Failed to fetch currencies');
    }
  };

  const handleFinish = async (values) => {
    try {
      const payload = {
        ...values,
      };

      if (!payload.currencyId) {
        payload.currencyId = currencies[currencies.length - 1]?.currencyId;
      }

      if (handlingCost) {
        // Update handling cost
        const updatedValues = { ...payload, handlingCostId: handlingCost.handlingCostId };
        await axios.put(`${config.apiUrl}/handlingcost/${handlingCost.handlingCostId}`, updatedValues);
        message.success('Handling cost updated successfully');
      } else {
        // Create new handling cost
        await axios.post(`${config.apiUrl}/handlingcost`, payload);
        message.success('Handling cost created successfully');
      }
      onSave(); // Refresh the list after save
      onClose(); // Close the drawer
    } catch (error) {
      console.error('There was an error saving the handling cost!', error);
      message.error('Failed to save handling cost');
    }
  };

  return (
    <Drawer
      title={handlingCost ? 'Edit Handling Cost' : 'Create Handling Cost'}
      width={720}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input placeholder="Please enter the description" />
        </Form.Item>
        <Form.Item
          name="cost"
          label="Cost"
          rules={[{ required: true, message: 'Please enter the cost' }]}
        >
          <InputNumber
            min={0}
            placeholder="Enter the cost"
            style={{ width: '100%' }}
            addonBefore={
              <Form.Item name="currencyId" noStyle>
                <Select
                  placeholder="Select Currency"
                  style={{ width: 120 }}
                >
                  {currencies.map((currency) => (
                    <Option key={currency.currencyId} value={currency.currencyId}>
                      {currency.symbol} - {currency.currencyCode}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {handlingCost ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default HandlingCostForm;
