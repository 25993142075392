import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Space, Typography, Tabs, Select, message, Divider, Table, InputNumber, Switch, Spin, Row, Col, Popconfirm, Tooltip } from 'antd';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import config from './Config';

const { Title } = Typography;
const { Option } = Select;

const PriceListForm = () => {
  const [form] = Form.useForm();
  const [basePriceLists, setBasePriceLists] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [isEditingPriceList, setIsEditingPriceList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adjustmentType, setAdjustmentType] = useState('all');
  const [materialAdjustmentType, setMaterialAdjustmentType] = useState('all');
  const [expenseAdjustmentType, setExpenseAdjustmentType] = useState('all');
  const [customAdjustment, setCustomAdjustment] = useState(0);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchBasePriceLists();
    fetchCategories();
    fetchExpenseTypes();
    if (id) {
      fetchPriceList(id);
      setIsEditingPriceList(true);
    } else {
      form.resetFields();
      setIsEditingPriceList(false);
      setIsFinished(false);
      form.setFieldsValue({ basePriceListId: 0, isFinished: false });
      fetchBasePriceList(0);
    }
  }, [id]);

  const fetchBasePriceLists = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/pricelists`);
      const priceLists = response.data;
      setBasePriceLists([{ priceListId: 0, description: 'Latest Prices' }, ...priceLists]);
    } catch (error) {
      console.error('Error fetching all price lists:', error);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchExpenseTypes = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/expenseType`);
      setExpenseTypes(response.data);
    } catch (error) {
      console.error('Error fetching expense types:', error);
    }
  };

  const fetchBasePriceList = async (priceListId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/pricelists/base/${priceListId}`);
      const data = response.data;
      setLaborData(data.pricesListLabor.map((item, index) => ({ ...item, key: index })));
      setMaterialData(data.pricesListMaterial.map((item, index) => ({ ...item, key: index })));
      setExpenseData(data.pricesListExpenses.map((item, index) => ({ ...item, key: index })));
    } catch (error) {
      console.error('Error fetching base price list:', error);
    }
    setLoading(false);
  };

  const fetchPriceList = async (priceListId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/pricelists/${priceListId}`);
      const data = response.data;
      form.setFieldsValue(data);
      setLaborData(data.pricesListLabor.map((item, index) => ({ ...item, key: index })));
      setMaterialData(data.pricesListMaterial.map((item, index) => ({ ...item, key: index })));
      setExpenseData(data.pricesListExpenses.map((item, index) => ({ ...item, key: index })));
      setIsFinished(data.isFinished);
    } catch (error) {
      console.error('Error fetching price list:', error);
    }
    setLoading(false);
  };

  const handleInputChange = (value, key, data, setData, field) => {
    const newData = data.map((item) => {
      if (item.key === key) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setData(newData);
  };

  const handleBasePriceListChange = async (value) => {
    form.setFieldsValue({ basePriceListId: value });
    await fetchBasePriceList(value);
  };

  const handleSwitchChange = (checked) => {
    setIsFinished(checked);
    // Update isFinished state and save the form
    handleSubmit(checked);
  };

  const handleSubmit = async (finishedStatus) => {
    setLoading(true);
    const priceListData = {
      ...form.getFieldsValue(),
      pricesListLabor: laborData,
      pricesListMaterial: materialData.map((item) => ({
        ...item,
      })),
      pricesListExpenses: expenseData.map((item) => ({
        ...item,
        transportationRate: parseFloat(item.transportationRate),
      })),
      isFinished: Boolean(finishedStatus !== undefined ? finishedStatus : isFinished),
      currencyId: form.getFieldValue('currencyId'), // Include currencyId
    };

    try {
      if (isEditingPriceList) {
        priceListData.priceListId = id;
        await axios.put(`${config.apiUrl}/pricelists/${id}`, priceListData);
        message.success('Price List updated successfully');
      } else {
        await axios.post(`${config.apiUrl}/pricelists`, priceListData);
        message.success('Price List created successfully');
      }
      navigate('/pricelists');
    } catch (error) {
      console.error('Error saving price list:', error);
      message.error('Failed to save price list');
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const adjustLaborPrices = (percentage) => {
    const adjustmentFactor = 1 + percentage / 100;
    const newLaborData = laborData.map((item) => {
      if (adjustmentType === 'all' || adjustmentType === 'hourlyRate') {
        item.hourlyRate = parseFloat((item.hourlyRate * adjustmentFactor).toFixed(2));
      }
      if (adjustmentType === 'all' || adjustmentType === 'minAllowance') {
        item.minAllowance = parseFloat((item.minAllowance * adjustmentFactor).toFixed(2));
      }
      return { ...item };
    });
    setLaborData(newLaborData);
    setCustomAdjustment(0);
  };

  const adjustMaterialPrices = (percentage) => {
    const adjustmentFactor = 1 + percentage / 100;
    const newMaterialData = materialData.map((item) => {
      if (materialAdjustmentType === 'all' || item.categoryId === materialAdjustmentType) {
        item.unitPrice = parseFloat((item.unitPrice * adjustmentFactor).toFixed(2));
      }
      return { ...item };
    });
    setMaterialData(newMaterialData);
    setCustomAdjustment(0);
  };

  const adjustExpensePrices = (percentage) => {
    const adjustmentFactor = 1 + percentage / 100;
    const newExpenseData = expenseData.map((item) => {
      // Convert expenseTypeId to string for proper comparison
      console.log(`Adjusting expense: ${item.expenseName}, expenseTypeId: ${item.expenseTypeId}, adjustmentType: ${expenseAdjustmentType}`);
      if (expenseAdjustmentType === 'all' || item.expenseTypeId.toString() === expenseAdjustmentType.toString()) {
        item.baseAmount = parseFloat((item.baseAmount * adjustmentFactor).toFixed(2));
        console.log(`Adjusted baseAmount for ${item.expenseName}: ${item.baseAmount}`);
      }
      return { ...item };
    });
    setExpenseData(newExpenseData);
    setCustomAdjustment(0);
  };

  const resetPrices = async () => {
    if (id) {
      await fetchPriceList(id);
    } else {
      await fetchBasePriceList(0);
    }
  };

  const laborColumns = [
    { title: 'Type', dataIndex: 'laborType', key: 'laborType', ...getColumnSearchProps('laborType') },
    { title: 'Description', dataIndex: 'description', key: 'description', responsive: ['md'], ...getColumnSearchProps('description') },
    {
      title: 'Hr. Rate',
      dataIndex: 'hourlyRate',
      key: 'hourlyRate',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) => handleInputChange(value, record.key, laborData, setLaborData, 'hourlyRate')}
          readOnly={isFinished}
          onKeyDown={handleKeyDown}
          prefix={record.currencySymbol}
        />
      ),
    },
    {
      title: 'Min Allowance',
      dataIndex: 'minAllowance',
      key: 'minAllowance',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) => handleInputChange(value, record.key, laborData, setLaborData, 'minAllowance')}
          readOnly={isFinished}
          onKeyDown={handleKeyDown}
          prefix={record.minAllowanceCurrencySymbol}
        />
      ),
    },
  ];

  const materialColumns = [
    { title: 'Material Name', dataIndex: 'materialName', key: 'materialName', ...getColumnSearchProps('materialName') },
    { title: 'SKU', dataIndex: 'sku', key: 'sku', responsive: ['md'], ...getColumnSearchProps('sku') },
    { title: 'Category', dataIndex: 'category', key: 'category', responsive: ['md'], ...getColumnSearchProps('category') },
    { title: 'Unit', dataIndex: 'uom', key: 'uom', ...getColumnSearchProps('uom') },
    { title: 'Tax Rate', dataIndex: 'taxRate', key: 'taxRate', render: (text) => `${(text * 100).toFixed(2)}%`, responsive: ['md'] },
    {
      title: 'Handling Cost',
      dataIndex: 'handlingCost',
      key: 'handlingCost',
      render: (text, record) => (
        <span>{record.handlingCostCurrencySymbol} {text}</span>
      ),
      responsive: ['md'],
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) => handleInputChange(value, record.key, materialData, setMaterialData, 'unitPrice')}
          readOnly={isFinished}
          onKeyDown={handleKeyDown}
          prefix={record.currencySymbol}
        />
      ),
    },
  ];

  const expenseColumns = [
    { title: 'Expense Name', dataIndex: 'expenseName', key: 'expenseName', ...getColumnSearchProps('expenseName') },
    { title: 'Type', dataIndex: 'expenseType', key: 'expenseType', responsive: ['md'], ...getColumnSearchProps('expenseType') },
    { title: 'Description', dataIndex: 'description', key: 'description', responsive: ['md'], ...getColumnSearchProps('description') },
    {
      title: 'Trans. Rate',
      dataIndex: 'transportationRate',
      key: 'transportationRate',
      render: (text, record) => (
        <span>{record.transportationRateCurrencySymbol}{text} per/km</span>
      ),
    },
    {
      title: 'Base Amount',
      dataIndex: 'baseAmount',
      key: 'baseAmount',
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) => handleInputChange(value, record.key, expenseData, setExpenseData, 'baseAmount')}
          readOnly={isFinished}
          onKeyDown={handleKeyDown}
          prefix={record.currencySymbol}
        />
      ),
    },
  ];

  const items = [
    {
      key: '1',
      label: 'Labor',
      children: (
        <div>
          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col span={10}>
              <Select defaultValue="all" onChange={value => setAdjustmentType(value)} style={{ width: '90%' }}>
                <Option value="all">All</Option>
                <Option value="hourlyRate">All Hourly Rates</Option>
                <Option value="minAllowance">All Allowances</Option>
              </Select>
            </Col>
            <Col span={14}>
              <Space>
                <Tooltip title="Decimal and negative values allowed">
                  <InputNumber
                    value={customAdjustment}
                    onChange={setCustomAdjustment}
                    style={{ maxWidth: 80 }}
                    suffix="%"
                  />
                </Tooltip>
                <Button type="primary" onClick={() => adjustLaborPrices(customAdjustment)} style={{ width: 50 }}>Apply</Button>
                <Popconfirm
                  title="Are you sure you want to reset the prices?"
                  onConfirm={resetPrices}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="dashed" style={{ width: 50 }}>Reset</Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={laborData}
            columns={laborColumns}
            rowClassName="editable-row"
            pagination={true}
            size="small"
          />
        </div>
      ),
    },
    {
      key: '2',
      label: 'Materials',
      children: (
        <div>
          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col span={10}>
              <Select defaultValue="all" onChange={value => setMaterialAdjustmentType(value)} style={{ width: '90%' }} optionFilterProp="children" showSearch>
                <Option value="all">All Categories</Option>
                {categories.map(category => (
                  <Option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={14}>
              <Space>
                <Tooltip title="Decimal and negative values allowed">
                  <InputNumber
                    value={customAdjustment}
                    onChange={setCustomAdjustment}
                    style={{ maxWidth: 80 }}
                    suffix="%"
                  />
                </Tooltip>
                <Button type="primary" onClick={() => adjustMaterialPrices(customAdjustment)} style={{ width: 50 }}>Apply</Button>
                <Popconfirm
                  title="Are you sure you want to reset the prices?"
                  onConfirm={resetPrices}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="dashed" style={{ width: 50 }}>Reset</Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={materialData}
            columns={materialColumns}
            rowClassName="editable-row"
            pagination={true}
            size="small"
          />
        </div>
      ),
    },
    {
      key: '3',
      label: 'Expenses',
      children: (
        <div>
          <Row gutter={16} justify="center" style={{ marginBottom: 16 }}>
            <Col span={10}>
              <Select defaultValue="all" onChange={value => setExpenseAdjustmentType(value)} style={{ width: '90%' }} optionFilterProp="children" showSearch>
                <Option value="all">All Expense Types</Option>
                {expenseTypes.map(type => (
                  <Option key={type.expenseTypeId} value={type.expenseTypeId}>
                    {type.expenseTypeName}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={14}>
              <Space>
                <Tooltip title="Decimal and negative values allowed">
                  <InputNumber
                    value={customAdjustment}
                    onChange={setCustomAdjustment}
                    style={{ maxWidth: 80 }}
                    suffix="%"
                  />
                </Tooltip>
                <Button type="primary" onClick={() => adjustExpensePrices(customAdjustment)} style={{ width: 50 }}>Apply</Button>
                <Popconfirm
                  title="Are you sure you want to reset the prices?"
                  onConfirm={resetPrices}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="dashed" style={{ width: 50 }}>Reset</Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
          <Table
            bordered
            dataSource={expenseData}
            columns={expenseColumns}
            rowClassName="editable-row"
            pagination={true}
            size="small"
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={loading}>
        <Title level={3}>{isEditingPriceList ? 'Edit Price List' : 'Create Price List'}</Title>
        <Form form={form} layout="vertical" onFinish={() => handleSubmit(isFinished)} initialValues={{ isFinished: false, basePriceListId: 0 }}>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please enter a description' }]}>
            <Input disabled={isFinished} />
          </Form.Item>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item name="basePriceListId" label="Base Price List">
                <Select onChange={handleBasePriceListChange} disabled={isFinished}>
                  {basePriceLists.map((basePriceList) => (
                    <Option key={basePriceList.priceListId} value={basePriceList.priceListId}>
                      {basePriceList.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="isFinished" label="Finished" valuePropName="checked">
                <Popconfirm
                  title="Are you sure you want to finish the price list?"
                  onConfirm={() => handleSwitchChange(true)}
                  onCancel={() => setIsFinished(false)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Switch checked={isFinished} disabled={isFinished}/>
                </Popconfirm>
              </Form.Item>
            </Col>
          </Row>
          <Tabs items={items} />
          <Divider />
          <Space>
            <Button type="primary" htmlType="submit" disabled={isFinished} style={{ padding: 8 }} icon={<SaveOutlined />}>
              Save
            </Button>
            <Button onClick={() => navigate(`/pricelists`)}>Cancel</Button>
          </Space>
        </Form>
      </Spin>
    </div>
  );
};

export default PriceListForm;
