import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Button,
  InputNumber,
  Select,
  message,
  Typography,
  Divider,
  Tabs,
  Space,
  Popconfirm
} from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  AuditOutlined,
  ToolOutlined,
  AppstoreOutlined,
  BuildOutlined,
  ClusterOutlined,
  DollarOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './Config';
import ProjectExchangeRate from './ProjectExchangeRate'; // Import the component

const { Option } = Select;
const { Paragraph } = Typography;

const ProjectCostsForm = ({ projectId }) => {
  const [loading, setLoading] = useState(false);
  const [exchangeRates, setExchangeRates] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [laborOptions, setLaborOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [bundleOptions, setBundleOptions] = useState([]);
  const [expenseOptions, setExpenseOptions] = useState([]);
  const navigate = useNavigate();

  const baseURL = `${config.apiUrl}`;

  // Fetch options for dropdowns
  const fetchOptions = useCallback(async () => {
    setLoading(true);
    try {
      const [
        laborRes,
        materialRes,
        moduleRes,
        bundleRes,
        expenseRes
      ] = await Promise.all([
        axios.get(`${baseURL}/labor`),
        axios.get(`${baseURL}/materials`),
        axios.get(`${baseURL}/modules/index`),
        axios.get(`${baseURL}/modulescomposite`),
        axios.get(`${baseURL}/expense`)
      ]);

      setLaborOptions(
        laborRes.data.map(item => ({ id: item.laborId, name: item.laborType }))
      );
      setMaterialOptions(
        materialRes.data.map(item => ({
          id: item.materialId,
          name: item.materialName
        }))
      );
      setModuleOptions(
        moduleRes.data.map(item => ({
          id: item.moduleId,
          name: item.longDescription
        }))
      );
      setBundleOptions(
        bundleRes.data.map(item => ({
          id: item.moduleCompositeId,
          name: item.compositeName
        }))
      );
      setExpenseOptions(
        expenseRes.data.map(item => ({
          id: item.expenseId,
          name: item.expenseName
        }))
      );
    } catch (error) {
      console.error('Error fetching options:', error);
      message.error('Failed to load options. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [baseURL]);

  // Fetch existing project costs
  const fetchProjectCosts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/projectdetails/${projectId}`);
      const projectCosts = response.data;

      setExchangeRates(projectCosts.projectExchangeRates || []);
      setLaborData(
        projectCosts.projectLabor.map(l => ({
          ...l,
          hoursRequired: l.hoursRequired || 0
        })) || []
      );
      setMaterialData(projectCosts.projectMaterials || []);
      setModuleData(projectCosts.projectModules || []);
      setBundleData(
        (projectCosts.projectModuleComposites || []).map(b => {
          const matchingOption = bundleOptions.find(
            opt => opt.id === b.moduleCompositeId
          );
          return {
            ...b,
            bundleId: matchingOption ? matchingOption.id : b.moduleCompositeId // Ensure `bundleId` matches an option
          };
        })
      );
      setExpenseData(projectCosts.projectExpenses || []);
    } catch (error) {
      console.error('Error fetching project costs:', error);
      message.error('Failed to load project costs. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [projectId, baseURL, bundleOptions]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  useEffect(() => {
    if (bundleOptions.length > 0) {
      fetchProjectCosts();
    }
  }, [fetchProjectCosts, bundleOptions]);

  // Add new row
  const handleAddRow = (setData, defaultRow) => {
    setData(prev => [...prev, { key: Date.now(), ...defaultRow }]);
  };

  // Delete row
  const handleDelete = (index, fieldKey) => {
    const dataMap = {
      labor: laborData,
      material: materialData,
      module: moduleData,
      expense: expenseData,
      bundle: bundleData
    };
    const setDataMap = {
      labor: setLaborData,
      material: setMaterialData,
      module: setModuleData,
      expense: setExpenseData,
      bundle: setBundleData
    };
    const newData = [...dataMap[fieldKey]];
    newData.splice(index, 1);
    setDataMap[fieldKey](newData);
  };

  // Save changes
  const handleSubmit = async () => {
    // Validate data before submission
    const isValid = (data, fieldKey) => {
      return data.every(
        item => item[`${fieldKey}Id`] && item.quantity > 0
      );
    };

    if (
      !isValid(laborData, 'labor') ||
      !isValid(materialData, 'material') ||
      !isValid(moduleData, 'module') ||
      !isValid(bundleData, 'bundle') ||
      !isValid(expenseData, 'expense')
    ) {
      message.error(
        'Please fill in all required fields and ensure quantities are greater than zero.'
      );
      return;
    }

    setLoading(true);
    const payload = {
      projectId,
      projectLabor: laborData.map(l => ({
        ...l,
        hoursRequired: l.hoursRequired || 0
      })),
      projectMaterials: materialData,
      projectModules: moduleData,
      projectModuleComposites: bundleData.map(b => ({
        ...b,
        moduleCompositeId: b.bundleId // Map bundleId back to moduleCompositeId
      })),
      projectExpenses: expenseData
    };
    try {
      await axios.post(`${baseURL}/projectdetails/${projectId}`, payload);
      message.success('Project costs saved successfully!');
      fetchProjectCosts();
    } catch (error) {
      console.error('Error saving project costs:', error);
      message.error('Failed to save project costs. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * createColumns
   * Dynamically create columns for the table. "Hours Required" is now only shown for labor.
   */
  const createColumns = (fieldKey, options = [], additionalColumns = []) => {
    // Common columns: "Type"
    const commonColumns = [
      {
        title: 'Type',
        dataIndex: `${fieldKey}Id`,
        render: (value, _, index) => {
          // Map data arrays and setters for convenience:
          const dataMap = {
            labor: laborData,
            material: materialData,
            module: moduleData,
            expense: expenseData
          };
          const setDataMap = {
            labor: setLaborData,
            material: setMaterialData,
            module: setModuleData,
            expense: setExpenseData
          };

          return (
            <Select
              showSearch
              value={value}
              onChange={val => {
                const newData = [...dataMap[fieldKey]];
                newData[index][`${fieldKey}Id`] = val;
                setDataMap[fieldKey](newData);
              }}
              style={{ width: '100%' }}
              placeholder={`Select ${fieldKey}`}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.map(opt => (
                <Option key={opt.id} value={opt.id}>
                  {opt.name}
                </Option>
              ))}
            </Select>
          );
        },
        width: '50%'
      }
    ];

    // Only add "Hours Required" if we're in the labor tab
    if (fieldKey === 'labor') {
      commonColumns.push({
        title: 'Hours Required',
        dataIndex: 'hoursRequired',
        render: (value, _, index) => (
          <InputNumber
            value={value}
            onChange={val => {
              const newData = [...laborData];
              newData[index].hoursRequired = val;
              setLaborData(newData);
            }}
            style={{ width: '100%' }}
          />
        ),
        width: '20%'
      });
    }

    // Always add the "Quantity" + "Delete" column
    commonColumns.push({
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (value, _, index) => {
        const dataMap = {
          labor: laborData,
          material: materialData,
          module: moduleData,
          expense: expenseData
        };
        const setDataMap = {
          labor: setLaborData,
          material: setMaterialData,
          module: setModuleData,
          expense: setExpenseData
        };

        return (
          <Space>
            <InputNumber
              value={value}
              onChange={val => {
                const newData = [...dataMap[fieldKey]];
                newData[index].quantity = val;
                setDataMap[fieldKey](newData);
              }}
              style={{ width: '80%' }}
            />
            <Popconfirm
              title="Are you sure you want to delete this item?"
              onConfirm={() => handleDelete(index, fieldKey)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
      width: '20%'
    });

    // Merge in any additionalColumns (for bundles, etc.)
    return [...commonColumns, ...additionalColumns];
  };

  // Define our tabs
  const tabs = [
    {
      key: '1',
      label: (
        <>
          <ToolOutlined />
          <span className="tab-label">Labor ({laborData.length})</span>
        </>
      ),
      children: (
        <>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() =>
              handleAddRow(setLaborData, {
                laborId: null,
                quantity: 0,
                hoursRequired: 0
              })
            }
            style={{ marginBottom: 16 }}
          >
            Add Labor
          </Button>
          <Table
            dataSource={laborData}
            columns={createColumns('labor', laborOptions)}
            pagination={false}
            rowKey="key"
          />
        </>
      )
    },
    {
      key: '2',
      label: (
        <>
          <AppstoreOutlined />
          <span className="tab-label">
            Materials ({materialData.length})
          </span>
        </>
      ),
      children: (
        <>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() =>
              handleAddRow(setMaterialData, {
                materialId: null,
                quantity: 0
              })
            }
            style={{ marginBottom: 16 }}
          >
            Add Material
          </Button>
          <Table
            dataSource={materialData}
            columns={createColumns('material', materialOptions)}
            pagination={false}
            rowKey="key"
          />
        </>
      )
    },
    {
      key: '3',
      label: (
        <>
          <BuildOutlined />
          <span className="tab-label">Modules ({moduleData.length})</span>
        </>
      ),
      children: (
        <>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() =>
              handleAddRow(setModuleData, {
                moduleId: null,
                quantity: 0
              })
            }
            style={{ marginBottom: 16 }}
          >
            Add Module
          </Button>
          <Table
            dataSource={moduleData}
            columns={createColumns('module', moduleOptions)}
            pagination={false}
            rowKey="key"
          />
        </>
      )
    },
    {
      key: '4',
      label: (
        <>
          <ClusterOutlined />
          <span className="tab-label">Bundles ({bundleData.length})</span>
        </>
      ),
      children: (
        <>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() =>
              handleAddRow(setBundleData, {
                bundleId: null,
                quantity: 0
              })
            }
            style={{ marginBottom: 16 }}
          >
            Add Bundle
          </Button>
          <Table
            dataSource={bundleData}
            columns={[
              {
                title: 'Type',
                dataIndex: 'bundleId',
                render: (value, _, index) => (
                  <Select
                    value={value}
                    onChange={val => {
                      const newData = [...bundleData];
                      newData[index] = { ...newData[index], bundleId: val };
                      setBundleData(newData);
                    }}
                    style={{ width: '100%' }}
                    placeholder="Select Bundle"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {bundleOptions.map(opt => (
                      <Option key={opt.id} value={opt.id}>
                        {opt.name}
                      </Option>
                    ))}
                  </Select>
                ),
                width: '60%'
              },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
                render: (value, _, index) => (
                  <Space>
                    <InputNumber
                      value={value}
                      onChange={val => {
                        const newData = [...bundleData];
                        newData[index] = {
                          ...newData[index],
                          quantity: val
                        };
                        setBundleData(newData);
                      }}
                      style={{ width: '80%' }}
                    />
                    <Popconfirm
                      title="Are you sure you want to delete this item?"
                      onConfirm={() => handleDelete(index, 'bundle')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                ),
                width: '40%'
              }
            ]}
            pagination={false}
            rowKey="key"
          />
        </>
      )
    },
    {
      key: '5',
      label: (
        <>
          <DollarOutlined />
          <span className="tab-label">Expenses ({expenseData.length})</span>
        </>
      ),
      children: (
        <>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() =>
              handleAddRow(setExpenseData, {
                expenseId: null,
                quantity: 0
              })
            }
            style={{ marginBottom: 16 }}
          >
            Add Expense
          </Button>
          <Table
            dataSource={expenseData}
            columns={createColumns('expense', expenseOptions)}
            pagination={false}
            rowKey="key"
          />
        </>
      )
    }
  ];

  return (
    <>
      <Paragraph>
        This step is for adding labor, materials, modules, bundles, and expenses
        to calculate project costs. Please edit the details below.
      </Paragraph>
      <Divider />
      <ProjectExchangeRate projectId={projectId} exchangeRates={exchangeRates} />
      <Tabs items={tabs} />
      <Divider />
      <div style={{ textAlign: 'right', marginTop: 16 }}>
        <Button
          type="dashed"
          style={{ marginRight: 8 }}
          onClick={() => navigate(`/project/${projectId}/pricereview`)}
          icon={<AuditOutlined />}
        >
          Review Prices
        </Button>
        <Button type="primary" onClick={handleSubmit} icon={<SaveOutlined />}>
          Save
        </Button>
      </div>
    </>
  );
};

export default ProjectCostsForm;
