import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, TreeSelect, InputNumber } from 'antd';
import config from './Config';
import axios from 'axios';

const { Option } = Select;

const MaterialForm = ({
  form,
  onSubmit,
  onCancel,
  isEditing,
  initialValues,
  categories,
  suppliers,
  taxRates,
  handlingCosts,
}) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (currencies.length > 0) {
      if (isEditing && initialValues) {
        initializeFormWithValues();
      } else {
        initializeFormWithDefaults();
      }
    }
  }, [isEditing, initialValues, currencies]);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/currencies/active`);
      setCurrencies(response.data);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
    }
  };

  const initializeFormWithValues = () => {
    if (!initialValues) return;
    const defaultCurrencyId = currencies[currencies.length - 1]?.currencyId;
    form.setFieldsValue({
      ...initialValues,
      currencyId: initialValues.currencyId ?? defaultCurrencyId,
    });
  };

  const initializeFormWithDefaults = () => {
    const defaultCurrencyId = currencies[currencies.length - 1]?.currencyId;
    form.resetFields();
    form.setFieldsValue({
      materialName: '',
      sku: '',
      categoryId: null,
      supplierId: null,
      unitPrice: null,
      currencyId: defaultCurrencyId,
      uom: '',
      taxRateId: null,
      handlingCostId: null,
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="materialName"
        label="Material Name"
        rules={[{ required: true, message: 'Please enter the material name' }]}
      >
        <Input placeholder="Please enter the material name" />
      </Form.Item>
      <Form.Item
        name="sku"
        label="SKU"
        rules={[{ required: true, message: 'Please enter the SKU' }]}
      >
        <Input placeholder="Please enter the SKU" />
      </Form.Item>
      <Form.Item
        name="categoryId"
        label="Category"
        rules={[{ required: true, message: 'Please select a category' }]}
      >
        <TreeSelect
          placeholder="Please select a category"
          treeData={categories}
          treeDefaultExpandAll
        />
      </Form.Item>
      <Form.Item
        name="supplierId"
        label="Supplier"
        rules={[{ required: true, message: 'Please select a supplier' }]}
      >
        <Select placeholder="Please select a supplier">
          {suppliers.map((supplier) => (
            <Option key={supplier.supplierId} value={supplier.supplierId}>
              {supplier.supplierName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="unitPrice"
        label="Unit Price"
        rules={[{ required: true, message: 'Please enter the unit price' }]}
      >
        <InputNumber
          min={0}
          placeholder="Please enter the unit price"
          style={{ width: '100%' }}
          addonBefore={
            <Form.Item name="currencyId" noStyle>
              <Select placeholder="Select Currency" style={{ width: 120 }}>
                {currencies.map((currency) => (
                  <Option key={currency.currencyId} value={currency.currencyId}>
                    {currency.symbol} - {currency.currencyCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      <Form.Item
        name="uom"
        label="Unit of Measure"
        rules={[{ required: true, message: 'Please enter the unit of measure' }]}
      >
        <Input placeholder="Please enter the unit of measure" />
      </Form.Item>
      <Form.Item
        name="taxRateId"
        label="Tax Rate"
        rules={[{ required: true, message: 'Please select a tax rate' }]}
      >
        <Select placeholder="Please select a tax rate">
          {taxRates.map((taxRate) => (
            <Option key={taxRate.taxRateId} value={taxRate.taxRateId}>
              {taxRate.longDescription}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="handlingCostId"
        label="Handling Cost"
        rules={[{ required: true, message: 'Please select a handling cost' }]}
      >
        <Select placeholder="Please select a handling cost">
          {handlingCosts.map((handlingCost) => (
            <Option key={handlingCost.handlingCostId} value={handlingCost.handlingCostId}>
              {handlingCost.longDescription}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default MaterialForm;
