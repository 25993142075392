const theme = {
    token: {
      brandColor: '#5b6d92',
      colorPrimary: '#5b6d92', // Your primary color
      colorSecondary: '#FFFFFF', // Your secondary color
      colorBgBase: '#FAF9F6', // Your background color
      colorTextBase: '#364042', // Base text color
      colorLink: '#D18266',
      borderRadius: '8px',
      Button: {
        primaryShadow: "none"
      },
    },
  };
  
  export default theme;