import React, { useEffect, useState } from 'react';
import { Form, Input, Button, InputNumber, Select } from 'antd';
import axios from 'axios';
import config from './Config';

const { TextArea } = Input;
const { Option } = Select;

const LaborForm = ({ form, onSubmit, onCancel, isEditing, initialValues }) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (currencies.length > 0) {
      if (isEditing && initialValues) {
        initializeFormWithValues();
      } else {
        initializeFormWithDefaults();
      }
    }
  }, [isEditing, initialValues, currencies]);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/currencies/active`);
      const activeCurrencies = response.data;
      setCurrencies(activeCurrencies);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
    }
  };

  const initializeFormWithValues = () => {
    if (!initialValues) return; // Guard clause to prevent errors if initialValues is missing
    const defaultCurrencyId = currencies[currencies.length - 1]?.currencyId;
    form.setFieldsValue({
      ...initialValues,
      currencyId: initialValues.currencyId ?? defaultCurrencyId,
      minAllowanceCurrencyId: initialValues.minAllowanceCurrencyId ?? defaultCurrencyId,
    });
  };

  const initializeFormWithDefaults = () => {
    const defaultCurrencyId = currencies[currencies.length - 1]?.currencyId;
    form.resetFields();
    form.setFieldsValue({
      laborType: '',
      description: '',
      hourlyRate: null,
      minAllowance: null,
      currencyId: defaultCurrencyId,
      minAllowanceCurrencyId: defaultCurrencyId,
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item
        name="laborType"
        label="Short Description"
        rules={[{ required: true, message: 'Please enter the labor type' }]}
      >
        <Input placeholder="Please enter the labor type" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please enter the description' }]}
      >
        <TextArea placeholder="Please enter the description" rows={4} />
      </Form.Item>
      <Form.Item
        name="hourlyRate"
        label="Hourly Rate"
        rules={[{ required: true, message: 'Please enter the hourly rate' }]}
      >
        <InputNumber
          min={0}
          placeholder="Please enter the hourly rate"
          style={{ width: '100%' }}
          addonBefore={
            <Form.Item name="currencyId" noStyle>
              <Select placeholder="Select Currency" style={{ width: 120 }}>
                {currencies.map((currency) => (
                  <Option key={currency.currencyId} value={currency.currencyId}>
                    {currency.symbol} - {currency.currencyCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      <Form.Item
        name="minAllowance"
        label="Minimum Allowance"
        rules={[{ required: true, message: 'Please enter the minimum allowance' }]}
      >
        <InputNumber
          min={0}
          placeholder="Please enter the minimum allowance"
          style={{ width: '100%' }}
          addonBefore={
            <Form.Item name="minAllowanceCurrencyId" noStyle>
              <Select placeholder="Select Currency" style={{ width: 120 }}>
                {currencies.map((currency) => (
                  <Option key={currency.currencyId} value={currency.currencyId}>
                    {currency.symbol} - {currency.currencyCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={handleCancel}
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default LaborForm;
