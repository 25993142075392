import React, { useState, useEffect } from 'react';
import { List, Button, Drawer, Form, Input, message, Row, Col, InputNumber } from 'antd';
import { EditOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './Config';

const ProjectExchangeRate = ({ projectId, exchangeRates, onRatesUpdated }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rates, setRates] = useState([...exchangeRates]);
  const [showRates, setShowRates] = useState(true);

  const baseURL = `${config.apiUrl}`;

  useEffect(() => {
    setRates([...exchangeRates]);
  }, [exchangeRates]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const toggleRates = () => {
    setShowRates(!showRates);
  };

  const handleRateChange = (index, value) => {
    const newRates = [...rates];
    newRates[index] = { ...newRates[index], rate: value, inverseRate: (1 / value).toFixed(2) };
    setRates(newRates);
  };

  const handleInverseRateChange = (index, value) => {
    const newRates = [...rates];
    newRates[index] = { ...newRates[index], inverseRate: value, rate: (1 / value).toFixed(2) };
    setRates(newRates);
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      await axios.put(`${baseURL}/projectdetails/${projectId}/exchangerates`, rates);
      message.success('Exchange rates updated successfully');
      onClose();
      if (onRatesUpdated) {
        onRatesUpdated(rates);
      }
    } catch (error) {
      console.error('Error updating exchange rates:', error);
      message.error('Failed to update exchange rates. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showRates && (
        <List
          header={
            <Row style={{ width: '100%' }}>
              <Col span={8}><strong>Target </strong></Col>
              <Col span={8}><strong>Rate </strong></Col>
              <Col span={8}><strong>Inverse Rate</strong></Col>
            </Row>
          }
          bordered
          dataSource={rates}
          size='small'
          renderItem={(rate, index) => (
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>{rate.targetCurrencyCode} ({rate.targetCurrencySymbol})</Col>
                <Col span={8}> {rate.currencySymbol}1 = {rate.targetCurrencySymbol}{parseFloat(rate.rate).toFixed(2)}</Col>
                <Col span={8}> {rate.targetCurrencySymbol}1 = {rate.currencySymbol}{parseFloat(rate.inverseRate).toFixed(2)} </Col>
              </Row>
            </List.Item>
          )}
        />
      )}
      <Button type="primary" onClick={showDrawer} style={{ marginTop: 16 }}>
        <EditOutlined /> Edit Rates
      </Button>
      <Button type="primary" onClick={toggleRates} style={{ marginBottom: 16 }}>
        {showRates ? <EyeInvisibleOutlined /> : <EyeOutlined />} {showRates ? 'Hide' : 'Show'}
      </Button>
      <Drawer
        title="Edit Exchange Rates"
        width={720}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish}>
          {rates.map((rate, index) => (
            <Row gutter={16} key={rate.exchangeRateId}>
              <Col span={12}>
                <Form.Item label={`${rate.currencySymbol}1 = (${rate.targetCurrencyCode})`} required>
                  <InputNumber
                    value={rate.rate}
                    onChange={(value) => handleRateChange(index, value)}
                    addonBefore={rate.targetCurrencySymbol}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={`Inverse Rate (${rate.currencyCode})`} required>
                  <InputNumber
                    value={rate.inverseRate}
                    onChange={(value) => handleInverseRateChange(index, value)}
                    addonBefore={rate.currencySymbol}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ProjectExchangeRate;