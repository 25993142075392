// src/TransportationRateForm.js
import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Input, InputNumber, message, Select } from 'antd';
import axios from 'axios';
import config from './Config';

const { Option } = Select;

const TransportationRateForm = ({ visible, onClose, rate, onSave }) => {
  const [form] = Form.useForm();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (visible && currencies.length === 0) {
      fetchCurrencies();
    }

    if (rate) {
      form.setFieldsValue({
        ...rate,
        currencyId: rate.currencyId || currencies[currencies.length - 1]?.currencyId,
      });
    } else {
      form.resetFields();
      if (currencies.length > 0) {
        form.setFieldsValue({ currencyId: currencies[currencies.length - 1]?.currencyId });
      }
    }
  }, [rate, form, currencies, visible]);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/currencies/active`);
      const activeCurrencies = response.data.filter((currency) => currency.isEnabled);
      setCurrencies(activeCurrencies);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
      message.error('Failed to fetch currencies');
    }
  };

  const handleFinish = async (values) => {
    try {
      const payload = {
        ...values,
      };

      if (!payload.currencyId) {
        payload.currencyId = currencies[currencies.length - 1]?.currencyId;
      }

      if (rate) {
        // Update transportation rate
        const updatedValues = { ...payload, transportationRateId: rate.transportationRateId };
        await axios.put(`${config.apiUrl}/transportationrate/${rate.transportationRateId}`, updatedValues);
        message.success('Transportation rate updated successfully');
      } else {
        // Create new transportation rate
        await axios.post(`${config.apiUrl}/transportationrate`, payload);
        message.success('Transportation rate created successfully');
      }
      onSave(); // Refresh the list after save
      onClose(); // Close the drawer
    } catch (error) {
      console.error('There was an error saving the transportation rate!', error);
      message.error('Failed to save transportation rate');
    }
  };

  return (
    <Drawer
      title={rate ? 'Edit Transportation Rate' : 'Create Transportation Rate'}
      width={720}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish} initialValues={{ currencyId: currencies[currencies.length - 1]?.currencyId }}>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input placeholder="Please enter the description" />
        </Form.Item>
        <Form.Item
          name="ratePerKm"
          label="Rate per Km"
          rules={[{ required: true, message: 'Please enter the rate per km' }]}
        >
          <InputNumber
            min={0}
            placeholder="Enter the rate per kilometer"
            style={{ width: '100%' }}
            addonBefore={
              <Form.Item name="currencyId" noStyle>
                <Select
                  placeholder="Select Currency"
                  style={{ width: 120 }}
                >
                  {currencies.map((currency) => (
                    <Option key={currency.currencyId} value={currency.currencyId}>
                      {currency.symbol} - {currency.currencyCode}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {rate ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default TransportationRateForm;
