import React, { useEffect } from 'react';
import { Drawer, Form, Button, Input, message } from 'antd';
import axios from 'axios';
import config from './Config';

const TaxRateForm = ({ visible, onClose, taxRate, onSave }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (taxRate) {
        // Populate the form with the tax rate data for editing
        form.setFieldsValue({
          ...taxRate,
          rate: taxRate.rate * 100, // Convert rate to percentage for display
        });
      } else {
        // Clear the form when creating a new tax rate
        form.resetFields();
      }
    }
  }, [visible, taxRate, form]);

  const handleFinish = async (values) => {
    try {
      const payload = {
        ...values,
        rate: values.rate / 100, // Convert percentage back to decimal for storage
      };

      if (taxRate) {
        await axios.put(`${config.apiUrl}/taxrate/${taxRate.taxRateId}`, {
          ...payload,
          taxRateId: taxRate.taxRateId,
        });
        message.success('Tax rate updated successfully');
      } else {
        await axios.post(`${config.apiUrl}/taxrate`, payload);
        message.success('Tax rate created successfully');
      }

      onSave();
      onClose();
    } catch (error) {
      console.error('There was an error saving the tax rate!', error);
      message.error('Failed to save tax rate');
    }
  };

  return (
    <Drawer
      title={taxRate ? 'Edit Tax Rate' : 'Create Tax Rate'}
      width={720}
      onClose={onClose}
      open={visible}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="description"
          label="Tax Rate Description"
          rules={[{ required: true, message: 'Please enter the tax rate description' }]}
        >
          <Input placeholder="Please enter the tax rate description" />
        </Form.Item>
        <Form.Item
          name="rate"
          label="Tax Rate (%)"
          rules={[{ required: true, message: 'Please enter the tax rate value as a percentage' }]}
        >
          <Input
            type="number"
            addonAfter="%"
            placeholder="Please enter the tax rate value"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {taxRate ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default TaxRateForm;
