import React, { useEffect, useState } from 'react';
import { Tree, Button, Drawer, Form, Input, message, Popconfirm, Typography, Divider } from 'antd';
import axios from 'axios';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import config from './Config';

const { Title, Text } = Typography;

const SystemManager = () => {
  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [parentSystemId, setParentSystemId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchSystems();
  }, []);

  const fetchSystems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/system`);
      setSystems(response.data);
    } catch (error) {
      console.error('Error fetching systems!', error);
    } finally {
      setLoading(false);
    }
  };

  const buildTreeData = (systems, parentId = null) => {
    const children = systems
      .filter(system => system.parentSystemId === parentId)
      .map(system => ({
        title: (
          <div>
            <span>{system.systemCode} - {system.description}</span>
            <span style={{ marginLeft: 8 }}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleEdit(system)}
                style={{ marginRight: 8 }}
              />
              <Popconfirm
                title="Are you sure you want to delete this system?"
                onConfirm={() => handleDelete(system.systemId)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            </span>
          </div>
        ),
        key: system.systemId,
        children: buildTreeData(systems, system.systemId),
      }));

    if (parentId === null) {
      children.unshift({
        title: (
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handleAdd(null)}
            style={{ paddingLeft: 0 }}
          >
            Add System
          </Button>
        ),
        key: 'add-top-level',
      });
    } else {
      children.unshift({
        title: (
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handleAdd(parentId)}
            style={{ paddingLeft: 0 }}
          >
            Add Sub-System
          </Button>
        ),
        key: `add-${parentId}`,
      });
    }

    return children;
  };

  const handleAdd = (parentId = null) => {
    setIsEditing(false);
    setParentSystemId(parentId);
    setSelectedSystem(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const handleEdit = (system) => {
    setIsEditing(true);
    setSelectedSystem(system);
    setParentSystemId(system.parentSystemId);
    form.setFieldsValue({
      systemCode: system.systemCode,
      description: system.description,
      longDescription: system.longDescription,
      systemLevel: system.systemLevel,
    });
    setDrawerVisible(true);
  };

  const handleDelete = async (systemId) => {
    setLoading(true);
    try {
      await axios.delete(`${config.apiUrl}/system/${systemId}`);
      message.success('System deleted successfully');
      fetchSystems();
    } catch (error) {
      console.error('Error deleting system!', error);
      message.error('Failed to delete system');
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerOk = async () => {
    try {
      const values = await form.validateFields();
      const newSystem = {
        ...values,
        parentSystemId: parentSystemId || null,
      };
      if (isEditing) {
        if (!selectedSystem || !selectedSystem.systemId) {
          throw new Error('Selected system is not properly set.');
        }
        await axios.put(`${config.apiUrl}/system/${selectedSystem.systemId}`, {
          systemId: selectedSystem.systemId,
          ...newSystem,
        });
        message.success('System updated successfully');
      } else {
        await axios.post(`${config.apiUrl}/system`, newSystem);
        message.success('System added successfully');
      }
      setDrawerVisible(false);
      fetchSystems();
    } catch (error) {
      console.error('Error saving system!', error);
      message.error(`Failed to save system: ${error.message}`);
    }
  };

  const handleDrawerCancel = () => {
    setDrawerVisible(false);
  };

  const treeData = buildTreeData(systems);

  return (
    <div>
      <Title level={2}>System Management</Title>
      <Text>
        Use this page to manage the Uniformat II systems. You can add, edit, and delete systems, and organize them hierarchically.
      </Text>
      <Divider />
      <Tree
        treeData={treeData}
        loading={loading}
        style={{ padding: 10 }}
      />
      <Drawer
        title={isEditing ? 'Edit System' : 'Add System'}
        open={drawerVisible}
        onClose={handleDrawerCancel}
        width={400}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={handleDrawerCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={handleDrawerOk} type="primary">
              Save
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="systemCode"
            label="System Code"
            rules={[{ required: true, message: 'Please enter the system code' }]}
          >
            <Input placeholder="Enter system code" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter the description' }]}
          >
            <Input placeholder="Enter description" />
          </Form.Item>
          <Form.Item
            name="longDescription"
            label="Long Description"
          >
            <Input.TextArea placeholder="Enter detailed description" />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default SystemManager;
