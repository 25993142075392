import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  InputNumber,
  Button,
  Space,
  message,
  Divider,
  Typography,
} from 'antd';
import axios from 'axios';
import config from './Config';

const { Title, Text } = Typography;

const DistanceTierManager = () => {
  const [tiers, setTiers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTiers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/DistanceTiers`);
      setTiers(response.data);
    } catch (error) {
      console.error('Error fetching distance tiers:', error);
      message.error('Failed to load distance tiers.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTiers();
  }, [fetchTiers]);

  // Handle inline updates to the multiplier field
  const handleMultiplierChange = (value, record) => {
    setTiers((prevTiers) =>
      prevTiers.map((tier) =>
        tier.tierId === record.tierId
          ? { ...tier, multiplier: value }
          : tier
      )
    );
  };

  // Save all tiers in a single request (POST or PUT)
  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      // Adjust to PUT if that’s your actual method:
      await axios.post(`${config.apiUrl}/DistanceTiers`, tiers);
      message.success('Distance tiers updated successfully.');
    } catch (error) {
      console.error('Error updating distance tiers:', error);
      message.error('Failed to update distance tiers.');
    } finally {
      setLoading(false);
    }
  };

  // Define a single "Distance Range" column
  const columns = [
    {
      title: 'Distance Range (km)',
      dataIndex: 'rangeDisplay',
      key: 'rangeDisplay',
      width: 250,
      render: (_, record) => {
        const { minDistance, maxDistance } = record;
        // If your DB uses 9999 as a sentinel for "∞"
        if (maxDistance === 9999) {
          return `From ${minDistance} km onward`;
        }
        return `From ${minDistance} km to ${maxDistance} km`;
      },
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      width: 120,
      render: (text, record) => (
        <InputNumber
          min={0}
          step={0.01}
          value={record.multiplier}
          onChange={(value) => handleMultiplierChange(value, record)}
        />
      ),
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>Distance Tier Management</Title>
      <Text>
        Manage how your allowances scale with distance. Adjust the <em>multiplier</em> to fit your cost model.
      </Text>
      <Divider />

      <Space style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={handleSaveChanges} loading={loading}>
          Save Changes
        </Button>
      </Space>

      <Table
        columns={columns}
        dataSource={tiers}
        rowKey="tierId"
        loading={loading}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default DistanceTierManager;
