// src/ExpenseForm.js
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, InputNumber } from 'antd';
import axios from 'axios';
import config from './Config';

const { Option } = Select;

const ExpenseForm = ({ form, expenseTypes, transportationRates, onSubmit, onCancel, isEditing, initialValues }) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (currencies.length === 0) {
      fetchCurrencies();
    }

    if (isEditing && initialValues) {
      form.setFieldsValue({
        ...initialValues,
        currencyId: initialValues.currencyId,
      });
    } else if (!isEditing && currencies.length > 0) {
      form.resetFields();
      form.setFieldsValue({ currencyId: currencies[currencies.length - 1]?.currencyId });
    }
  }, [form, isEditing, initialValues, currencies]);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/currencies/active`);
      const activeCurrencies = response.data.filter((currency) => currency.isEnabled);
      setCurrencies(activeCurrencies);
    } catch (error) {
      console.error('Failed to fetch currencies', error);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="expenseName"
        label="Short Description"
        rules={[{ required: true, message: 'Please enter the expense name' }]}
      >
        <Input placeholder="Please enter the expense name" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: false }]}
      >
        <Input.TextArea placeholder="Please enter a description" />
      </Form.Item>
      <Form.Item
        name="expenseTypeId"
        label="Expense Type"
        rules={[{ required: true, message: 'Please select an expense type' }]}
      >
        <Select placeholder="Please select an expense type">
          {expenseTypes.map(expenseType => (
            <Option key={expenseType.expenseTypeId} value={expenseType.expenseTypeId}>
              {expenseType.expenseTypeName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="transportationRateId"
        label="Transportation Rate"
        rules={[{ required: false, message: 'Please select a transportation rate' }]}
      >
        <Select placeholder="Please select a transportation rate">
          {transportationRates.map(rate => (
            <Option key={rate.transportationRateId} value={rate.transportationRateId}>
              {rate.longDescription}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="baseAmount"
        label="Base Amount"
        rules={[{ required: false }]}
      >
        <InputNumber
          min={0}
          placeholder="Please enter the base amount"
          style={{ width: '100%' }}
          addonBefore={
            <Form.Item name="currencyId" noStyle>
              <Select placeholder="Select Currency" style={{ width: 120 }}>
                {currencies.map((currency) => (
                  <Option key={currency.currencyId} value={currency.currencyId}>
                    {currency.symbol} - {currency.currencyCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default ExpenseForm;
